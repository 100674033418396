
  export default {
    name: `SelectionCard`,
    props: {
      value: {
        type: Object,
        default: undefined
      },
      selected: {
        type: Boolean,
        default: undefined
      },
      item: {
        type: Object,
        default: undefined
      },
      color: {
        type: String,
        default: `white`
      },
      title: {
        type: String,
        default: undefined
      },
      tooltip: {
        type: String,
        default: undefined
      },
      description: {
        type: String,
        default: undefined
      },
      footerDescription: {
        type: String,
        default: undefined
      },
      icon: {
        type: String,
        default: undefined
      },
      disabledColor: {
        type: String,
        default: `grey lighten-3`
      },
      disabled: {
        type: Boolean,
        default: false
      },
      height: {
        type: Number,
        default: undefined
      },
      width: {
        type: Number,
        default: undefined
      },
      ribbonText: {
        type: String,
        default: ``
      }
    },
    computed: {
      getColor() {
        return this.disabled ? this.disabledColor : this.color
      }
    }
  }
